var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicOverlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}]},[_c('div',{staticClass:"modal-window",class:_vm.bem('modal-window', { popup: _vm.popup, withEmbed: _vm.withEmbed, appearance: _vm.appearance })},[_c('div',{ref:"boxRef",staticClass:"modal-window__scroll-content",class:_vm.bem('modal-window__scroll-content', { appearance: _vm.appearance }),style:({
        transform: ("translateY(" + _vm.topTranslate + "px)"),
        opacity: _vm.opacity,
      }),on:{"mousedown":_vm.onModalClick}},[(_vm.appearance === 'new')?_c('div',{ref:"sausageRef",staticClass:"modal-window__sausage"}):_vm._e(),_c('div',{ref:"wrapper",staticClass:"modal-window__wrapper",class:_vm.bem('modal-window__wrapper', { appearance: _vm.appearance })},[_c('div',{staticClass:"modal-window__box",class:_vm.bem('modal-window__box', { popup: _vm.popup, withEmbed: _vm.withEmbed, appearance: _vm.appearance })},[(_vm.hasHeader)?_c('div',{staticClass:"modal-window__header",class:_vm.bem('modal-window__header', {
                popup: _vm.popup,
                withEmbed: _vm.withEmbed,
                appearance: _vm.appearance,
                scrollable: _vm.isContentScrollable,
                stuck: !_vm.topIsReached,
              })},[(_vm.$listeners.back)?_c('button',{staticClass:"modal-window__back",on:{"click":function($event){return _vm.$emit('back', $event)}}},[_c('ArrowIcon',{attrs:{"direction":"left","size":"28","color":"dark"}})],1):_vm._e(),(_vm.title)?_c('div',{staticClass:"modal-window__title",class:_vm.bem('modal-window__title', { appearance: _vm.appearance }),domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('button',{staticClass:"modal-window__close",class:_vm.bem('modal-window__close', { popup: _vm.popup, withEmbed: _vm.withEmbed, appearance: _vm.appearance }),attrs:{"type":"button"},on:{"click":_vm.onClose}},[_c('SvgIcon',{attrs:{"icon":"cross","size":"18","color":_vm.popup ? 'muted-blue' : 'dark'}})],1)]):_vm._e(),_c('div',{staticClass:"modal-window__content",class:_vm.bem('modal-window__content', { popup: _vm.popup, withEmbed: _vm.withEmbed, appearance: _vm.appearance })},[(_vm.$slots.default || _vm.$slots.body)?_c('div',{ref:"bodyRef",staticClass:"modal-window__body",class:_vm.bem('modal-window__body', { scrollable: _vm.isContentScrollable })},[_vm._t("default"),_vm._t("body")],2):_vm._e()]),_c('TransitionExpand',[(_vm.$slots.footer)?_c('div',{staticClass:"modal-window__footer",class:_vm.bem('modal-window__footer', {
                  popup: _vm.popup,
                  withEmbed: _vm.withEmbed,
                  appearance: _vm.appearance,
                  scrollable: _vm.isContentScrollable,
                  stuck: !_vm.bottomIsReached,
                })},[_vm._t("footer")],2):_vm._e()])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }