import { usePusher } from 'src/services/pusher.js';

document.addEventListener('DOMContentLoaded', () => {
  const { pusher } = usePusher();

  if (!pusher) return;

  function checkLocation(login) {
    const { pathname } = window.location;

    return pathname !== `/${login}` && !pathname.startsWith('/profile');
  }

  function onSuccess(login) {
    window.helpers.notify(
      `Обновление данных в вашем профиле завершено.<br/>Проверьте, все ли правильно. <a href="/${login}">В профиль</a>`,
      'notice',
      3600,
    );
  }

  pusher.on(
    'import_profile_status_updated',
    ({
      data: {
        login,
        currentImport: { status },
      },
    }) => {
      if (checkLocation() && status === 'success') {
        onSuccess(login);
      }
    },
  );
});
